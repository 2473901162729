<!--
 * @Description: 
 * @Autor: 洛埋名
 * @Date: 2022-11-25 11:51:46
 * @LastEditors: 洛埋名
 * @LastEditTime: 2022-12-04 14:54:02
-->
<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const gameList = ref();
gameList.value = [
  { link: '/shuerte', name: '舒尔特方格' },
  { link: '/shuziquanquanle', name: '数字圈圈乐' },
  { link: '/baishouzhanji', name: '百兽战记' },
  { link: '/wodeshijie', name: '我的世界' },
  { link: '/caisechuanzhu', name: '彩色串珠' },
  { link: '/shuzitutule', name: '数字涂涂乐' },
  { link: '/wangwangdui1', name: '汪汪队1' },
  { link: '/wangwangdui2', name: '汪汪队2' },
  { link: '/wangwangdui3', name: '汪汪队3' },
  { link: '/wangwangdui4', name: '汪汪队4' },
  { link: '/wangwangdui5', name: '汪汪队拼图' },
  { link: '/gongchengche', name: '工程车认知' },
  { link: '/aoteman', name: '奥特曼抽卡' },
  { link: '/xunzhaobaozhang', name: '寻找宝藏' },
  { link: '/qiangdajiangshi', name: '保卫植物' },
  { link: '/zhitouzi', name: '投掷骰子' },
];
const toPath = (link) => {
  console.log(link);
  router.push(link);
};
// 计算属性
</script>

<template>
  <div class="home-page">
    <h1>桐桐的游戏小世界</h1>
    <div class="gameList">
      <div class="game" v-for="(item, key) in gameList" :key="key">
        <el-button class="btnCss" type="success" size="large" @click="toPath(item.link)">{{ item.name }}</el-button>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.home-page {
  text-align: center;
  .gameList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .game {
      // float: left;
      width: 40%;
      margin: 10px;
      button {
        width: 60%;
      }
    }
  }
}
</style>
