<!--
 * @Description: 
 * @Autor: 洛埋名
 * @Date: 2022-11-08 10:15:45
 * @LastEditors: 洛埋名
 * @LastEditTime: 2022-11-27 10:19:31
-->
<template>
  <div class="game-box-mobile public-css">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" v-if="Reload.isRouterAlive" />
      </transition>
    </router-view>
  </div>
</template>

<script setup>
import { provide, reactive, onMounted } from 'vue';

// 页面切换/刷新 动画
const Reload = reactive({
  isRouterAlive: true,
  reload: () => {
    // 如果刷新，取消动画
    Reload.isRouterAlive = false;
    setTimeout(() => {
      Reload.isRouterAlive = true;
    }, 500);
  },
});
// 全局注入刷新 动画
provide('reload', Reload.reload);
// 生命周期 - 挂载完成（可以访问DOM元素）,做初始化操作
onMounted(() => {
  console.log('%c Code & Theme By 洛埋名', 'color:#C779D0');
  console.log('%c QQ:416784788', 'color:#C779D0');
});
</script>

<style lang="less" scoped>
// 手机CSS
.game-box-mobile {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 8px 8px 0;
}

// 游戏公用的css
.public-css {
  background-color: antiquewhite;
  border: 0.1px solid transparent;
  // padding: 0 8px;

  /* 页面切换动画 - 开始 */
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.5s;
    transition-property: opacity;
  }

  .fade-enter-active {
    transition-delay: 0.5s;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }

  /* 页面切换动画 - 结束 */
}
</style>
