import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/shuerte',
    name: 'shuerte',
    component: () => import('../views/Shuerte.vue'),
  },
  {
    path: '/shuziquanquanle',
    name: 'shuziquanquanle',
    component: () => import('../views/Shuziquanquanle.vue'),
  },
  {
    path: '/baishouzhanji',
    name: 'baishouzhanji',
    component: () => import('../views/Baishouzhanji.vue'),
  },
  {
    path: '/wodeshijie',
    name: 'wodeshijie',
    component: () => import('../views/Wodeshijie.vue'),
  },
  {
    path: '/caisechuanzhu',
    name: 'caisechuanzhu',
    component: () => import('../views/Caisechuanzhu.vue'),
  },
  {
    path: '/shuzitutule',
    name: 'shuzitutule',
    component: () => import('../views/Shuzitutule.vue'),
  },
  {
    path: '/wangwangdui1',
    name: 'wangwangdui1',
    component: () => import('../views/Wangwangdui1.vue'),
  },
  {
    path: '/wangwangdui2',
    name: 'wangwangdui2',
    component: () => import('../views/Wangwangdui2.vue'),
  },
  {
    path: '/wangwangdui3',
    name: 'wangwangdui3',
    component: () => import('../views/Wangwangdui3.vue'),
  },
  {
    path: '/wangwangdui4',
    name: 'wangwangdui4',
    component: () => import('../views/Wangwangdui4.vue'),
  },
  {
    path: '/wangwangdui5',
    name: 'wangwangdui5',
    component: () => import('../views/Wangwangdui5.vue'),
  },
  {
    path: '/gongchengche',
    name: 'gongchengche',
    component: () => import('../views/Gongchengche.vue'),
  },
  {
    path: '/aoteman',
    name: 'aoteman',
    component: () => import('../views/Aoteman.vue'),
  },
  {
    path: '/xunzhaobaozhang',
    name: 'xunzhaobaozhang',
    component: () => import('../views/Xunzhaobaozhang.vue'),
  },
  {
    path: '/qiangdajiangshi',
    name: 'qiangdajiangshi',
    component: () => import('../views/Qiangdajiangshi.vue'),
  },
  {
    path: '/zhitouzi',
    name: 'zhitouzi',
    component: () => import('../views/Zhitouzi.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
